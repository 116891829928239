import * as React from 'react'
import { Grid2, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';

import Navbar from '../../Setup/Navbar'
import HeadingTest from '../../../BuiltInComponent/HeadingTest'
import { getTrackingSummery } from '../../../features/Clients/reports/trackingSlice'
import { useDispatch, useSelector } from 'react-redux'

export default function TrackingViewDetails() {

    const dispatch = useDispatch()

    const { summery, totals } = useSelector(state => state.trck.summery)

    React.useEffect(() => {
        dispatch(getTrackingSummery())
    }, [dispatch])

    const tableColumns = [
        { fieldName: 'mhName', cName: 'Row Labels', status: 'en', textAlign: 'center', dType: 'string' },
        { fieldName: 'contNoCount', cName: 'Count', status: 'en', textAlign: 'center', dType: 'number' },
        { fieldName: 'avgwareHouseArrival', cName: 'Avg Days', status: 'en', textAlign: 'center', dType: 'number' },
        { fieldName: 'totalamount', cName: 'CNF $', status: 'en', textAlign: 'center', dType: 'number' },
    ]
    return (
        <>
            <Navbar />
            <Grid2 container spacing={2}>
                <Grid2 size={4}></Grid2>
                <Grid2 size={4}>
                    <HeadingTest text={'Tracking Details'} />
                </Grid2>
                <Grid2 size={4}></Grid2>
                <Grid2 size={3}></Grid2>
                <Grid2 size={6}>
                    <StickyHeadTable tableColumns={tableColumns} tableData={summery} totals={totals} perPage={50} />
                </Grid2>
                <Grid2 size={3}></Grid2>
            </Grid2>
        </>
    )
}

function StickyHeadTable(props) {

    const theme = useTheme()

    const perPage = props.perPage
    const tableColumns = props.tableColumns
    const rows = props.tableData

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(perPage || 50);
    // const [filter, setFilter] = React.useState(''); // New state for filtering
    const [filteredRows, setFilteredRows] = React.useState(rows); // New state to hold filtered data

    // Handle pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // Handle the filter input change
    // const handleFilterChange = (event) => {
    //     const filterValue = event.target.value.toLowerCase();
    //     // setFilter(filterValue);

    //     // Filter rows based on filter input
    //     const filteredData = rows.filter(row => {
    //         return tableColumns.some(col => {
    //             const cellValue = row[col.fieldName]?.toString().toLowerCase();
    //             return cellValue && cellValue.includes(filterValue);
    //         });
    //     });

    //     setFilteredRows(filteredData);
    // };

    React.useEffect(() => {
        setFilteredRows(rows); // Update filteredRows if rows change
    }, [rows]);

    return (
        <Paper sx={{ width: '98%', overflow: 'hidden', backgroundColor: 'rgb(250, 250, 250)' }}>
            {/* <Grid2 container spacing={1}>
                <Grid2 size={4}></Grid2>
                <Grid2 size={4}>
                    <TextField
                        sx={{ margin: '20px' }}
                        label="Filter"
                        variant="outlined"
                        fullWidth
                        value={filter}
                        onChange={handleFilterChange}
                        placeholder="Search by any column..."
                        size='small'
                    />
                </Grid2>
                <Grid2 size={4}></Grid2>
            </Grid2> */}
            <TableContainer >
                <Table stickyHeader aria-label="sticky table" size='small'>
                    <TableHead>
                        <TableRow>
                            {tableColumns && tableColumns.map((columns, index) => (
                                tableColumns[index].status === 'en' ? (
                                    <TableCell
                                        key={columns.cName || index}
                                        style={{
                                            backgroundColor: theme.palette.primary.main,
                                            color: 'white',
                                            textAlign: `${tableColumns[index].textAlign}`,
                                            fontWeight: 'bolder',
                                            width: `${columns.width}`,
                                            textShadow: 'black 2px 2px 4px'
                                        }}
                                    >
                                        {columns.cName}
                                    </TableCell>
                                ) : null
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRows && filteredRows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, rowIndex) => {
                                return (
                                    <TableRow
                                        key={rowIndex}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        style={{ width: '70%', }}
                                    >
                                        {tableColumns.map((col, index) => (
                                            <TableCell
                                                key={tableColumns[index].fieldName || index}
                                                style={{
                                                    textAlign: `${tableColumns[index].dType === 'number' ?
                                                        index === 0 ? 'center' : 'right' : 'left'}`,
                                                    fontSize: rowIndex === rows.length - 1 ? 15 : 13,
                                                    width: tableColumns[index].width,
                                                    cursor: index === 0 ? 'pointer' : '',
                                                }}
                                                component="th"
                                                scope="row"
                                                sx={{ border: 'ridge 1px' }}
                                            >
                                                {tableColumns[index].dType === 'number'
                                                    ? `${row[tableColumns[index].fieldName] ? Math.round(row[tableColumns[index].fieldName]).toLocaleString("en-US") : '0'}`
                                                    : row[tableColumns[index].fieldName] ? row[tableColumns[index].fieldName] : '0'}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                );
                            })}
                        <TableRow >
                            <TableCell style={{ fontWeight: 'bold', fontSize: 18, textAlign: 'center' }}>TOTAL</TableCell>
                            <TableCell style={{ fontWeight: 'bold', fontSize: 18, textAlign: 'right' }}>{props.totals && props.totals.totalContNoCount}</TableCell>
                            <TableCell style={{ fontWeight: 'bold', fontSize: 18, textAlign: 'right' }}>{props.totals && Math.round(props.totals.averageAvgwareHouseArrival).toLocaleString('en-us')}</TableCell>
                            <TableCell style={{ fontWeight: 'bold', fontSize: 18, textAlign: 'right' }}>{props.totals && Math.round(props.totals.totalAmount).toLocaleString('en-us')}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[50, 100, 200]}
                component="div"
                count={filteredRows?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}