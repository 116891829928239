import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Collapse,
    Box,
    Container,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { getTrackingBuyerWiseDetails } from "../../../features/Clients/reports/trackingSlice";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import Navbar from "../../Setup/Navbar";

const BuyerWiseTrackingDetails = () => {

    const dispatch = useDispatch()

    const { data } = useSelector(state => state.trck.bwd)
    const [openRows, setOpenRows] = useState({});

    const toggleRow = (month) => {
        setOpenRows((prev) => ({ ...prev, [month]: !prev[month] }));
    };

    useEffect(() => {
        dispatch(getTrackingBuyerWiseDetails())
    }, [])
    const mainHeading = { fontWeight: 'bolder', fontSize: 17, textAlign: 'center' }
    const mainData = { fontWeight: 'bolder', fontSize: 14, textAlign: 'right' }
    const subHeading = { fontWeight: 'bolder', fontSize: 13, textAlign: 'center' }
    const subData = { fontSize: 13, textAlign: 'right' }
    return (
        <>
            <Navbar />
            <Container>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow >
                                <TableCell />
                                <TableCell sx={mainHeading}>Row Labels</TableCell>
                                <TableCell sx={mainHeading}>Total Containers</TableCell>
                                <TableCell sx={mainHeading}>Avg Days</TableCell>
                                <TableCell sx={mainHeading}>Total Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data && data.map((row) => (
                                <>
                                    {/* Summary Row */}
                                    <TableRow key={row.mhName}>
                                        <TableCell>
                                            <IconButton size="small" onClick={() => toggleRow(row.mhName)}>
                                                {openRows[row.mhName] ? (
                                                    <KeyboardArrowUp />
                                                ) : (
                                                    <KeyboardArrowDown />
                                                )}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell sx={{ ...mainData, textAlign: 'left' }}>{row.mhName}</TableCell>
                                        <TableCell sx={mainData}>{row.mhContainers}</TableCell>
                                        <TableCell sx={mainData}>{Math.round(row.mhAvgDays / row.mhContainers).toLocaleString('us-en')}</TableCell>
                                        <TableCell sx={mainData}>$ {Math.round(row.mhAmount).toLocaleString('us-en')}</TableCell>
                                    </TableRow>
                                    {/* Details Row */}
                                    <TableRow>
                                        <TableCell colSpan={3} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                            <Collapse in={openRows[row.mhName]} timeout="auto" unmountOnExit>
                                                <Box margin={1}>
                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell sx={subHeading}>No's of Containers</TableCell>
                                                                <TableCell sx={subHeading}>Date</TableCell>
                                                                <TableCell sx={subHeading}>Avg Days</TableCell>
                                                                <TableCell sx={subHeading}>Amount</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {row.dates.map((detail, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell sx={{ subData, textAlign: 'center' }}>{detail.totalContainers}</TableCell>
                                                                    <TableCell sx={subData}>{dayjs(detail.arrivalDate).format('DD-MM-YYYY')}</TableCell>
                                                                    <TableCell sx={subData}>{Math.round(detail.avgArrDays / detail.totalContainers).toLocaleString('us-en')}</TableCell>
                                                                    <TableCell sx={subData}>{Math.round(detail.totalAmount).toLocaleString('us-en')}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow >
                                </>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container >
        </>
    );
};

export default BuyerWiseTrackingDetails;
