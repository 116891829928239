import { createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashbaord from "./compnents/Setup/Dashbaord";
import SignIn from "./compnents/CleintComponent/SignIn";
import AddNewUser from "./compnents/Security/AddNewUser";
import AddNewMenu from "./compnents/Security/AddNewMenu";
import SetUserRights from './compnents/Security/SetUserRights'
import SectionWiseProduct from "./compnents/reports/production/Production";
import BuyerWiseDeepSales from "./compnents/reports/sales/BuyerWiseDeepSales";
import BuyerWiseStock from "./compnents/stock/BuyerWiseStock";
import SalesStock from "./compnents/sales/SalesReport"
// import TrackingReport from "./compnents/reports/tracking/TrackingReport";
import TrackingViewDetails from './compnents/reports/tracking/TrackingViewDetails'
import BuyerWiseTrackingDetails from './compnents/reports/tracking/BuyerWiseTrackingDetails'
import MonthWiseTrackingDetails from "./compnents/reports/tracking/MonthWiseTrackingDetails";
import TestingMasterReport from './compnents/reports/testing/TestingMasterReport'
import TrackingDeepDetails from './compnents/reports/tracking/TrackingDeepDetails'

import ForSB from './compnents/ForSB'

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f9cff'
    },
    secondary: {
      main: '#ffffff',
    },
    custom: {
      main: "#ffffff"
    }
  },
  typography: {
    fontFamily: [
      'Calibri'
    ].join(','),
  },
})

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          {/* Security Routes Starts */}
          <Route path="/" element={<Dashbaord />} />
          {/* Admin Starts */}
          <Route path="/SignIn" element={<SignIn />} />
          <Route path="/AddNewuser" element={<AddNewUser />} />
          <Route path="/AddNewMenu" element={<AddNewMenu />} />
          <Route path="/SetUserRights" element={<SetUserRights />} />
          {/* Admin Ends */}

          {/* Production Report Starts */}
          <Route path="/Production/SectionWiseProduct" element={<SectionWiseProduct />} />
          {/* Production Report Ends */}

          {/* Buyer Wise Stock Starts */}
          <Route path="/Stock/BuyerWiseStock" element={<BuyerWiseStock />} />
          {/* Buyer Wise Stock Ends */}

          {/* Sales Report Starts */}
          <Route path="/Sales/SalesReport" element={<SalesStock />} />
          <Route path="/Sales/DeepSalesReport" element={<BuyerWiseDeepSales />} />
          {/* Sales Report Ends */}

          {/* Tracking Reports Starts */}
          <Route path="/Tracking/TrackingReport" element={<TrackingViewDetails />} />
          <Route path="/Tracking/BuyerWise" element={<BuyerWiseTrackingDetails />} />
          <Route path="/Tracking/MonthWise" element={<MonthWiseTrackingDetails />} />
          <Route path="/Tracking/TrackingDeepDetails" element={<TrackingDeepDetails />} />
          {/* Tracking Reports Ends */}

          {/* Testing Reports Starts */}
          <Route path="/Testing/MainReport" element={<TestingMasterReport />} />
          {/* Testing Reports Ends */}

          <Route path="/ForSB" element={<ForSB />} />
          {/* Security Routes Ends */}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
