import * as React from 'react';
import { Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';

export default function RowRadioButtonsGroup(props) {
    const arrayForButton = props.arrayForButton
    return (
        <FormControl sx={{ alignSelf: 'center' }}>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                alignSelf="center"
                value={props.selectedValue}
                onChange={(event) => {
                    const handleChangeRadioButtonFunc = props.handleChangeRadioButton
                    handleChangeRadioButtonFunc(event.target.value)
                }}
            >
                {arrayForButton.map((item) => (
                    <>
                        <FormControlLabel value={item.value} control={<Radio />} label={item.label} />
                    </>
                ))}
            </RadioGroup>
        </FormControl>
    );
}
