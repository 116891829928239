import React, { useEffect, useState } from "react";
import {
    Paper, TablePagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Container, Grid2, TextField
} from "@mui/material";
import { useTheme } from '@emotion/react';
import { getTestingMasterReport } from "../../../features/Clients/reports/testingSlice";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import Navbar from "../../Setup/Navbar";
const BuyerWiseTrackingDetails = () => {

    const dispatch = useDispatch()

    const { masterData } = useSelector(state => state.tstng)

    useEffect(() => {
        dispatch(getTestingMasterReport())
    }, [dispatch])

    // const columnsName = [
    //     { fieldName: 'locCode', cName: 'Loc Code', textAlign: '', status: 'en', dType: 'string' },
    //     { fieldName: 'sailingDate', cName: 'Sailing Date', textAlign: '', status: 'en', dType: 'date' },
    //     { fieldName: 'arrivalDate', cName: 'Arrival Date', textAlign: '', status: 'en', dType: 'date' },
    //     { fieldName: 'testingDate', cName: 'Test Date', textAlign: '', status: 'en', dType: 'date' },
    //     { fieldName: 'purchasedTillDate', cName: 'Purchased Till', textAlign: '', status: 'en', dType: 'number' },
    //     { fieldName: 'stcok', cName: 'Stock', textAlign: '', status: 'en', dType: 'string' },
    //     { fieldName: 'inTransit', cName: 'In Transit', textAlign: '', status: 'en', dType: 'string' },
    //     { fieldName: 'Loc_name', cName: 'Location', textAlign: '', status: 'en', dType: 'string' },
    //     { fieldName: 'score', cName: 'Score', textAlign: '', status: 'en', dType: 'string' },
    //     { fieldName: 'exportLBS', cName: 'Export/Lbs', textAlign: '', status: 'en', dType: 'string' },
    //     { fieldName: 'ragsPurchasedRate', cName: 'Loc Code', textAlign: '', status: 'en', dType: 'string' },
    //     { fieldName: 'diffPNL', cName: 'Loc Code', textAlign: '', status: 'en', dType: 'string' },
    //     { fieldName: 'summerRate', cName: 'Loc Code', textAlign: '', status: 'en', dType: 'string' },
    //     { fieldName: 'summerPercentage', cName: 'Loc Code', textAlign: '', status: 'en', dType: 'string' },
    //     { fieldName: 'winterRate', cName: 'Loc Code', textAlign: '', status: 'en', dType: 'string' },
    //     { fieldName: 'winterPercentage', cName: 'Loc Code', textAlign: '', status: 'en', dType: 'string' },
    //     { fieldName: 'otherRate', cName: 'Loc Code', textAlign: '', status: 'en', dType: 'string' },
    //     { fieldName: 'OtherPercentage', cName: 'Loc Code', textAlign: '', status: 'en', dType: 'string' },
    //     { fieldName: 'cutRate', cName: 'Loc Code', textAlign: '', status: 'en', dType: 'string' },
    //     { fieldName: 'cutPercentage', cName: 'Loc Code', textAlign: '', status: 'en', dType: 'string' },
    // ]

    return (
        <>
            <Navbar />
            <Container>
                <StickyHeadTable tableData={masterData && masterData} perPage={50} />
            </Container >
        </>
    );
};



// function StickyHeadTable(props) {

//     // const navigate = useNavigate()
//     const theme = useTheme()

//     const perPage = props.perPage
//     const tableColumns = props.tableColumns
//     const rows = props.tableData

//     const [page, setPage] = React.useState(0);
//     const [rowsPerPage, setRowsPerPage] = React.useState(perPage || 5);
//     const [filter, setFilter] = React.useState(''); // New state for filtering
//     const [filteredRows, setFilteredRows] = React.useState(rows); // New state to hold filtered data

//     // Handle pagination
//     const handleChangePage = (event, newPage) => {
//         setPage(newPage);
//     };

//     const handleChangeRowsPerPage = (event) => {
//         setRowsPerPage(+event.target.value);
//         setPage(0);
//     };

//     // Handle the filter input change
//     const handleFilterChange = (event) => {
//         const filterValue = event.target.value.toLowerCase();
//         setFilter(filterValue);

//         // Filter rows based on filter input
//         const filteredData = rows.filter(row => {
//             return tableColumns.some(col => {
//                 const cellValue = row[col.fieldName]?.toString().toLowerCase();
//                 return cellValue && cellValue.includes(filterValue);
//             });
//         });

//         setFilteredRows(filteredData);
//     };

//     React.useEffect(() => {
//         setFilteredRows(rows); // Update filteredRows if rows change
//     }, [rows]);

//     return (
//         <Paper sx={{ width: '98%', overflow: 'hidden', backgroundColor: 'rgb(250, 250, 250)' }}>
//             <Grid2 container spacing={1}>
//                 <Grid2 size={4}></Grid2>
//                 <Grid2 size={4}>
//                     <TextField
//                         sx={{ margin: '20px' }}
//                         label="Filter"
//                         variant="outlined"
//                         fullWidth
//                         value={filter}
//                         onChange={handleFilterChange}
//                         placeholder="Search by any column..."
//                         size='small'
//                     />
//                 </Grid2>
//                 <Grid2 size={4}></Grid2>
//             </Grid2>
//             <TableContainer >
//                 <Table stickyHeader aria-label="sticky table" size='small'>
//                     <TableHead>
//                         <TableRow>
//                             {tableColumns && tableColumns.slice(1, tableColumns.length).map((columns, index) => (
//                                 tableColumns[index + 1].status === 'en' ?
//                                     <>
//                                         <TableCell
//                                             key={index}
//                                             style={{
//                                                 backgroundColor: theme.palette.primary.main,
//                                                 color: 'white',
//                                                 textAlign: `${tableColumns[index + 1].textAlign}`,
//                                                 fontWeight: 'bolder',
//                                                 width: `${columns.width}`,
//                                                 textShadow: 'black 2px 2px 4px',
//                                                 whiteSpace: 'nowrap'
//                                             }}
//                                         >
//                                             {columns.cName}
//                                         </TableCell>
//                                     </> : <></>
//                             ))}
//                         </TableRow>
//                     </TableHead>
//                     <TableBody>
//                         {filteredRows && filteredRows
//                             .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                             .map((row, rowIndex) => {
//                                 return (
//                                     <TableRow
//                                         key={rowIndex}
//                                         sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
//                                         style={{ width: '70%', }}
//                                     >
//                                         {tableColumns.slice(1, tableColumns.length).map((col, index) => (
//                                             <TableCell
//                                                 key={index}
//                                                 style={{
//                                                     textAlign: `${tableColumns[index + 1].dType === 'number' ?
//                                                         index === 0 ? 'center' : 'right' : 'left'}`,
//                                                     fontSize: rowIndex === rows.length - 1 ? 15 : 13,
//                                                     width: tableColumns[index + 1].width,
//                                                     cursor: index === 0 ? 'pointer' : '',
//                                                     whiteSpace: 'nowrap'
//                                                 }}
//                                                 component="th"
//                                                 scope="row"
//                                                 sx={{ border: 'ridge 1px' }}
//                                             >
//                                                 {tableColumns[index + 1].dType === 'number'
//                                                     ? Math.round(row[tableColumns[index + 1].fieldName]).toLocaleString("en-US")
//                                                     : tableColumns[index + 1].dType === 'date' ? dayjs(row[tableColumns[index + 1].fieldName]).format('DD-MMM-YYYY') : row[tableColumns[index + 1].fieldName]}
//                                             </TableCell>
//                                         ))}
//                                     </TableRow>
//                                 );
//                             })}
//                     </TableBody>
//                 </Table>
//             </TableContainer>
//             <TablePagination
//                 rowsPerPageOptions={[50, 100, 200]}
//                 component="div"
//                 count={filteredRows && filteredRows.length}
//                 rowsPerPage={rowsPerPage}
//                 page={page}
//                 onPageChange={handleChangePage}
//                 onRowsPerPageChange={handleChangeRowsPerPage}
//             />
//         </Paper>
//     );
// }


function StickyHeadTable(props) {

    const theme = useTheme()

    const perPage = props.perPage
    // const tableColumns = props.tableColumns
    const rows = props.tableData

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(perPage || 5);
    // const [filter, setFilter] = React.useState(''); // New state for filtering
    const [filteredRows, setFilteredRows] = React.useState(rows); // New state to hold filtered data
    const [searchText, setSearchText] = useState('');

    // Handle pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const handleFilterChange = (event) => {
        setSearchText(event.target.value.toLowerCase());

        // Filter rows based on filter input
        const filteredData = rows.filter(
            (row) =>
                row.Loc_name.toLowerCase().includes(searchText.toLowerCase())
        );

        setFilteredRows(filteredData);
    };

    React.useEffect(() => {
        setFilteredRows(rows); // Update filteredRows if rows change
    }, [rows]);

    const headerStyle = {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        textAlign: `Center`,
        fontWeight: 'bolder',
        textShadow: 'black 2px 2px 4px',
        whiteSpace: 'nowrap'
    }
    return (
        <Paper sx={{ width: '98%', overflow: 'hidden', backgroundColor: 'rgb(250, 250, 250)' }}>
            <Grid2 container spacing={1}>
                <Grid2 size={4}></Grid2>
                <Grid2 size={4}>
                    <TextField
                        sx={{ margin: '20px' }}
                        label="Filter"
                        variant="outlined"
                        fullWidth
                        value={searchText}
                        onChange={handleFilterChange}
                        placeholder="Search by any column..."
                        size='small'
                    />
                </Grid2>
                <Grid2 size={4}></Grid2>
            </Grid2>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell style={headerStyle}>Test Date</TableCell>
                            <TableCell style={headerStyle}>Location</TableCell>
                            <TableCell style={headerStyle}>Exp Name</TableCell>
                            <TableCell style={headerStyle}>Score</TableCell>
                            <TableCell style={headerStyle}>Diff PNL</TableCell>
                            <TableCell style={headerStyle}>Export / Lbs</TableCell>
                            <TableCell style={headerStyle}>Rags Purchased</TableCell>
                            <TableCell style={headerStyle}>Summer Rate & Ratio</TableCell>
                            <TableCell style={headerStyle}>Winter Rate & Ratio</TableCell>
                            <TableCell style={headerStyle}>Other Rate & Ratio</TableCell>
                            <TableCell style={headerStyle}>Cut Rate & Ratio</TableCell>
                            <TableCell style={headerStyle}>Sailing Date</TableCell>
                            <TableCell style={headerStyle}>Arrival Date</TableCell>
                            <TableCell style={{ ...headerStyle, }}>Total Purchased</TableCell>
                            <TableCell style={headerStyle}>Stock</TableCell>
                            <TableCell style={headerStyle}>In Transit</TableCell>
                            <TableCell style={headerStyle}>Testing Unit</TableCell>
                            <TableCell style={headerStyle}>Loc Code</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRows &&
                            (() => {
                                let lastLocName = null; // Track the last locName
                                let colorIndex = 0; // Toggle between 0 and 1
                                const colors = ['#afefff', '#fff']; // Define two colors

                                return filteredRows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, rowIndex) => {
                                        // Change colorIndex when locName changes
                                        if (row.Loc_name !== lastLocName) {
                                            colorIndex = 1 - colorIndex; // Toggle between 0 and 1
                                            lastLocName = row.Loc_name; // Update the last locName
                                        }

                                        return (
                                            <TableRow
                                                key={rowIndex}
                                                sx={{
                                                    '&:last-child td, &:last-child th': { border: 0 },
                                                }}
                                                style={{
                                                    backgroundColor: colors[colorIndex],
                                                }}
                                            >
                                                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                    {dayjs(row.testingDate).format('DD-MMM-YY')}
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.Loc_name}</TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.Exp_Name}</TableCell>
                                                <TableCell style={{ textAlign: 'right' }}>{row.score}</TableCell>
                                                <TableCell style={{ textAlign: 'right' }} sx={{ whiteSpace: 'nowrap', borderRight: 'grey solid 1px' }}>
                                                    $ {parseFloat(row.diffPNL).toFixed(2).toLocaleString('en-US')}
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'right' }} sx={{ whiteSpace: 'nowrap' }}>
                                                    $ {parseFloat(row.exportLBS).toFixed(2).toLocaleString('en-US')}
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'right' }} sx={{ whiteSpace: 'nowrap' }}>
                                                    $ {parseFloat(row.ragsPurchasedRate).toFixed(4).toLocaleString('en-US')}
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'right' }} sx={{ whiteSpace: 'nowrap' }}>
                                                    $ {parseFloat(row.summerRate).toFixed(2).toLocaleString('en-US')} -{' '}
                                                    {parseFloat(row.summerPercentage).toFixed(0).toLocaleString('en-US')}%
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'right' }} sx={{ whiteSpace: 'nowrap' }}>
                                                    $ {parseFloat(row.winterRate).toFixed(2).toLocaleString('en-US')} -{' '}
                                                    {parseFloat(row.winterPercentage).toFixed(0).toLocaleString('en-US')}%
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'right' }} sx={{ whiteSpace: 'nowrap' }}>
                                                    $ {parseFloat(row.otherRate).toFixed(2).toLocaleString('en-US')} -{' '}
                                                    {parseFloat(row.OtherPercentage).toFixed(0).toLocaleString('en-US')}%
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'right' }} sx={{ whiteSpace: 'nowrap' }}>
                                                    $ {parseFloat(row.cutRate).toFixed(2).toLocaleString('en-US')} -{' '}
                                                    {parseFloat(row.cutPercentage).toFixed(0).toLocaleString('en-US')}%
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                    {dayjs(row.sailingDate).format('DD-MMM-YY')}
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                    {dayjs(row.arrivalDate).format('DD-MMM-YY')}
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'right' }}>
                                                    {row.purchasedTillDate}
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'right' }}>
                                                    {Math.round(row.stock).toLocaleString('us-en')}
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'right' }}>
                                                    {row.inTransit}
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.testingUnit}</TableCell>
                                                <TableCell>{row.locCode}</TableCell>
                                            </TableRow>
                                        );
                                    });
                            })()}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[50, 100, 200]}
                component="div"
                count={filteredRows && filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}


export default BuyerWiseTrackingDetails;
