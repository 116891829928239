import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import '../../../services/globalVariables'
import axios from 'axios'
import { getMenusIDWise } from '../../security/menuSlice'

const API_URL = global.API_URL + 'sales/'

const initialState = {
    buyerNames: [],
    itemNames: [],
    buyerWD: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: ""
}

export const getBuyerName = createAsyncThunk('ds/gbn', async (_, thunkAPI) => {
    try {
        const result = await axios.get(API_URL + 'getBuyerNamesLastDateWise')
        return result.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getItemsName = createAsyncThunk('ds/gin', async (_, thunkAPI) => {
    try {
        const result = await axios.get(API_URL + 'getmrgItemName')
        return result.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getBuyerWiseDeepSalesData = createAsyncThunk('ds/bwr', async (mhCode, thunkAPI) => {
    try {
        const result = await axios.post(API_URL + 'getBuyerWiseDeepSalesData', mhCode)
        return result.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getItemWiseDeepSalesData = createAsyncThunk('ds/iwr', async (mrgItemCode, thunkAPI) => {
    console.log('reach')
    try {
        const result = await axios.post(API_URL + 'getItemWiseDeepSalesData', mrgItemCode)
        return result.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const deepSaelsSlice = createSlice({
    name: 'menus',
    initialState,
    reducers: {
        reset: (state) => {
            state.buyerWD = []
            state.buyerNames = []
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ""
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getBuyerName.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getBuyerName.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.buyerNames = action.payload
            })
            .addCase(getBuyerName.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.buyerNames = []
            })
            .addCase(getItemsName.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getItemsName.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.itemNames = action.payload
            })
            .addCase(getItemsName.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.itemNames = []
            })
            .addCase(getBuyerWiseDeepSalesData.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getBuyerWiseDeepSalesData.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.buyerWD = action.payload
            })
            .addCase(getBuyerWiseDeepSalesData.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.buyerWD = []
            })
            .addCase(getItemWiseDeepSalesData.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getItemWiseDeepSalesData.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.buyerWD = action.payload
            })
            .addCase(getItemWiseDeepSalesData.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.buyerWD = []
            })
    }
})

export const { reset } = deepSaelsSlice.actions

export default deepSaelsSlice.reducer