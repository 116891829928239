import * as React from 'react'
import { Container, Grid2 } from '@mui/material'
import Navbar from '../Setup/Navbar'
import { useDispatch, useSelector } from 'react-redux'
import { getParentMenu, getMenusIDWise, addNewMenu } from '../../features/security/menuSlice'
import AutocompleteSelf from '../../BuiltInComponent/AutoComplete'
import TextFields from '../../BuiltInComponent/TextField'
import TableWithPagination from '../../BuiltInComponent/TableWithPagination'
import ButtonNormal from '../../BuiltInComponent/ButtonNormal'
import HeadingText from '../../BuiltInComponent/HeadingTest'
export default function AddNewMenu() {

    const dispatch = useDispatch()

    const { parentMenus, menuIDWise } = useSelector(state => state.menus)

    const { user } = useSelector(state => state.auth)

    const [menuData, setMenuData] = React.useState({
        userID: user.userID
        , menuName: ''
        , menuURL: ''
        , menuID: ''
    })

    React.useEffect(() => {
        dispatch(getParentMenu())
    }, [dispatch])

    const onChange = (data) => {
        const keys = Object.keys(data)
        setMenuData(prevData => ({
            ...prevData, [keys]: data[keys]
        }))
        if (keys[0] === 'menuID') {
            dispatch(getMenusIDWise({ menuParentID: data[keys] }))
        }
    }

    const tableColumns = [
        { fieldName: 'menuID', cName: 'User ID', width: 10, textAlign: 'left', status: 'en' },
        { fieldName: 'menuName', cName: 'Menu Name', width: 10, textAlign: 'left', status: 'en' },
        // { fieldName: 'allow', cName: 'Allow', width: 10, textAlign: 'center', status: 'en', swithButton: true },
        { fieldName: 'edit', cName: 'Edit', width: 10, textAlign: 'center', status: 'di' },
        { fieldName: 'delete', cName: 'Delete', width: 10, textAlign: 'center', status: 'di' },
    ]

    const AddNewRecord = () => {
        dispatch(addNewMenu(menuData)).then(() => {
            dispatch(getMenusIDWise({ menuParentID: menuData.menuID }))
            setMenuData({
                userID: user.userID
                , menuName: ''
                , menuURL: ''
                , menuID: menuData.menuID
            })
        })
    }

    return (
        <>
            <Navbar />
            <Container>
                <Grid2 container spacing={2}>
                    <Grid2 size={12}>
                        <HeadingText text='Add New Menu' />
                    </Grid2>
                    <Grid2 size={3}>
                        <AutocompleteSelf
                            listData={parentMenus && parentMenus}
                            onChange={onChange}
                            label='Select Parent Menu'
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <TextFields id='menuName' name='menuName' onChange={onChange} label='Menu Name' value={menuData.menuName} />
                    </Grid2>
                    <Grid2 size={3}>
                        <TextFields id='menuURL' name='menuURL' onChange={onChange} label='Menu URL' value={menuData.menuURL} />
                    </Grid2>
                    <Grid2 size={3}>
                        <ButtonNormal onClick={AddNewRecord} text='SAVE' />
                    </Grid2>
                    <Grid2 size={2}></Grid2>
                    <Grid2 size={8}>
                        <TableWithPagination
                            tableColumns={tableColumns}
                            tableData={menuIDWise}
                            perPage={50}
                        />
                    </Grid2>
                    <Grid2 size={2}></Grid2>
                </Grid2>
            </Container>

        </>
    )
}