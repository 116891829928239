import React, { useEffect, useState } from "react";
import {
  Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, IconButton, Collapse, Box,
  Container,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useDispatch, useSelector } from 'react-redux'
import { getTrackingMonthWiseDetails } from "../../../features/Clients/reports/trackingSlice";
import Navbar from "../../Setup/Navbar";
const MonthWiseTrackingDetails = () => {

  const dispatch = useDispatch()

  const { data } = useSelector(state => state.trck.mwd)

  const [openRows, setOpenRows] = useState({});

  const [openNestedRows, setOpenNestedRows] = useState(null); // Change to track a single nested row

  // Toggle for parent rows
  const toggleRow = (mhName) => {
    setOpenRows((prev) => ({ ...prev, [mhName]: !prev[mhName] }));
  };

  // Toggle for nested rows (first-level details) - allow only one open at a time
  const toggleNestedRow = (mhName, index) => {
    setOpenNestedRows((prev) =>
      prev === `${mhName}-${index}` ? null : `${mhName}-${index}`
    );
  };

  useEffect(() => {
    dispatch(getTrackingMonthWiseDetails())
  }, [])

  return (
    <>
      <Navbar />
      <Container>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell sx={{ fontSize: 18, fontWeight: 'bolder', textAlign: 'center' }}>Month Name</TableCell>
                <TableCell>Total Containers</TableCell>
                <TableCell>Average Days</TableCell>
                <TableCell>Total Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((row) => (
                  <>
                    {/* Summary Row (Parent) */}
                    <TableRow key={row.monthName}>
                      <TableCell>
                        <IconButton
                          size="small"
                          onClick={() => toggleRow(row.monthName)}
                        >
                          {openRows[row.monthName] ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowDown />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>{row.monthName}-{row.mhYear}</TableCell>
                      <TableCell>{row.mhContainers}</TableCell>
                      <TableCell>
                        {(row.mhAvgDays / row.mhContainers).toFixed()}
                      </TableCell>
                      <TableCell>${Math.round(row.mhAmount).toLocaleString('us-en')}</TableCell>
                    </TableRow>
                    {/* First Details Row (Child) */}
                    <TableRow>
                      <TableCell
                        colSpan={5}
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                      >
                        <Collapse
                          in={openRows[row.monthName]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box margin={1}>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell></TableCell>
                                  <TableCell>Arrival Date</TableCell>
                                  <TableCell>Total Containers</TableCell>
                                  <TableCell>Avg Days</TableCell>
                                  <TableCell>Total Amount</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {row.dates.map((detail, index) => {
                                  const avgArrivalDaysPerContainer = (
                                    detail.avgArrDays / detail.totalContainers
                                  ).toFixed();
                                  return (
                                    <>
                                      <TableRow key={index}>
                                        <TableCell>
                                          <IconButton
                                            size="small"
                                            onClick={() =>
                                              toggleNestedRow(row.monthName, index)
                                            }
                                          >
                                            {openNestedRows ===
                                              `${row.monthName}-${index}` ? (
                                              <KeyboardArrowUp />
                                            ) : (
                                              <KeyboardArrowDown />
                                            )}
                                          </IconButton>
                                        </TableCell>
                                        <TableCell>
                                          {new Date(
                                            detail.arrivalDate
                                          ).toLocaleDateString()}
                                        </TableCell>
                                        <TableCell>
                                          {detail.totalContainers}
                                        </TableCell>
                                        <TableCell>
                                          {avgArrivalDaysPerContainer}
                                        </TableCell>
                                        <TableCell>${Math.round(detail.totalAmount).toLocaleString('us-en')}</TableCell>
                                      </TableRow>
                                      {/* Nested Details Table (Buyers) */}
                                      <TableRow key={`${row.monthName}-${index}`}>
                                        <TableCell
                                          colSpan={5}
                                          style={{
                                            paddingBottom: 0,
                                            paddingTop: 0,
                                          }}
                                        >
                                          <Collapse
                                            in={
                                              openNestedRows ===
                                              `${row.monthName}-${index}`
                                            }
                                            timeout="auto"
                                            unmountOnExit
                                          >
                                            <Box margin={1}>
                                              <Table size="small">
                                                <TableHead>
                                                  <TableRow>
                                                    <TableCell>Buyer</TableCell>
                                                    <TableCell>Total Containers</TableCell>
                                                    <TableCell>Avg Days</TableCell>
                                                    <TableCell>Total Amount</TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {detail.buyers.map(
                                                    (buyer, buyerIndex) => (
                                                      <TableRow
                                                        key={`${row.monthName}-${index}-${buyerIndex}`}
                                                      >
                                                        <TableCell>
                                                          {buyer.buyer}
                                                        </TableCell>
                                                        <TableCell>
                                                          {buyer.totalContainers}
                                                        </TableCell>
                                                        <TableCell>
                                                          {buyer.avgArrDays}
                                                        </TableCell>
                                                        <TableCell>
                                                          ${Math.round(buyer.totalAmount).toLocaleString('us-en')}
                                                        </TableCell>
                                                      </TableRow>
                                                    )
                                                  )}
                                                </TableBody>
                                              </Table>
                                            </Box>
                                          </Collapse>
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};

export default MonthWiseTrackingDetails;
