import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/security/authSlice'
import menuReducer from '../features/security/menuSlice'
import analyzationReducer from '../features/Clients/analyzation/analyzationSlice'
import countriesReducer from '../features/Clients/countries/countriesSlice'
import userRightReducer from '../features/security/setUserRightsSlice'
import productionReducer from '../features/Clients/reports/productionSlice'
import salesReducer from '../features/Clients/sales/salesSlice'
import trackingReducer from '../features/Clients/reports/trackingSlice'
import testingReducer from '../features/Clients/reports/testingSlice'
import deepSalesREducer from '../features/Clients/reports/deepSalesReport'



export const store = configureStore({
    reducer: {
        auth: authReducer,
        menus: menuReducer,
        analyzation: analyzationReducer,
        countries: countriesReducer,
        sur: userRightReducer,
        pr: productionReducer,
        sales: salesReducer,
        trck: trackingReducer,
        tstng: testingReducer,
        ds: deepSalesREducer,
    },
})