import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import '../../../services/globalVariables'
import axios from 'axios'

const API_URL = global.API_URL + 'tracking/'

const initialState = {
    summery: [],
    bwd: [],
    mwd: [],
    tdd: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: ""
}

export const getTrackingSummery = createAsyncThunk('trck/smry', async (_, thunkAPI) => {
    try {
        const result = await axios.get(API_URL + '')
        return result.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getTrackingBuyerWiseDetails = createAsyncThunk('trck/tbwd', async (_, thunkAPI) => {
    try {
        const result = await axios.get(API_URL + 'getBuyerWiseDetails')
        return result.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getTrackingMonthWiseDetails = createAsyncThunk('trck/tmwd', async (_, thunkAPI) => {
    try {
        const result = await axios.get(API_URL + 'getMonthWiseDetails')
        return result.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getDeepDetailsData = createAsyncThunk('trck/tdd', async (_, thunkAPI) => {
    try {
        const result = await axios.get(API_URL + 'getDeepDetailsData')
        return result.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const trackingSlice = createSlice({
    name: 'menus',
    initialState,
    reducers: {
        reset: (state) => {
            state.summery = []
            state.bwd = []
            state.mwd = []
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ""
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getTrackingSummery.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getTrackingSummery.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.summery = action.payload
            })
            .addCase(getTrackingSummery.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.bwd = []
            })
            .addCase(getTrackingBuyerWiseDetails.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getTrackingBuyerWiseDetails.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.bwd = action.payload
            })
            .addCase(getTrackingBuyerWiseDetails.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.bwd = []
            })
            .addCase(getTrackingMonthWiseDetails.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getTrackingMonthWiseDetails.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.mwd = action.payload
            })
            .addCase(getTrackingMonthWiseDetails.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.mwd = []
            })
            .addCase(getDeepDetailsData.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getDeepDetailsData.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.tdd = action.payload
            })
            .addCase(getDeepDetailsData.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.tdd = []
            })
    }
})

export const { reset } = trackingSlice.actions

export default trackingSlice.reducer