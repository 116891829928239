import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import '../../../services/globalVariables'
import axios from 'axios'

const API_URL = global.API_URL + 'testing/'

const initialState = {
    masterData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: ""
}

export const getTestingMasterReport = createAsyncThunk('tstng/smry', async (_, thunkAPI) => {
    try {
        const result = await axios.get(API_URL + '')
        return result.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const testingSlice = createSlice({
    name: 'menus',
    initialState,
    reducers: {
        reset: (state) => {
            state.masterData = []
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ""
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getTestingMasterReport.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getTestingMasterReport.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.masterData = action.payload
            })
            .addCase(getTestingMasterReport.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.masterData = []
            })
    }
})

export const { reset } = testingSlice.actions

export default testingSlice.reducer